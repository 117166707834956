import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import FaxIcon from "@mui/icons-material/Fax";
import EmailIcon from "@mui/icons-material/Email";
import FooterStack from "./FooterStack";
import FooterItem from "./FooterItem";

const Footer = () => {
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          flexGrow: 1,
          background: theme.palette.primary.main,
          color: "white",
        }}
      >
        <Grid container>
          <Grid
            container
            item
            xs={6}
            md={6}
            sx={{ p: "1rem 0" }}
            alignItems="baseline"
          >
            <Container sx={{ pb: "1rem" }}>
              <Typography variant="body1">AUGUST GROH & SONS, INC.</Typography>
              <Typography variant="body2">80 Novner Drive</Typography>
              <Typography variant="body2">Cincinnati, Ohio 45215</Typography>
            </Container>
            <Container>
              <Typography
                variant="body1"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CallIcon fontSize="inherit" />: 513-821-0090
              </Typography>
            </Container>
            <Container sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body1"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FaxIcon fontSize="inherit" />: 513-679-4062
              </Typography>
            </Container>
            <Container sx={{ display: "flex", alignItems: "center", pr: 0 }}>
              <Typography
                variant="body1"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <EmailIcon fontSize="inherit" />:{" "}
                <Link sx={{ color: "#ffffff" }} href="mailto:pobox@groh.com">
                  pobox@groh.com
                </Link>
              </Typography>
            </Container>
          </Grid>
          <Grid container xs={6} sx={{ p: "1rem 0" }}>
            <Grid
              container
              item
              direction="row"
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item>
                <FooterStack
                  title="Services"
                  links={[
                    { linkTitle: "Painting", linkUrl: "/painting" },
                    { linkTitle: "Cleaning", linkUrl: "/cleaning" },
                  ]}
                />
              </Grid>
              <Grid item>
                <FooterStack
                  title="About Us"
                  links={[
                    { linkTitle: "Who We Are", linkUrl: "/about-us" },
                    { linkTitle: "Our Clients", linkUrl: "/clients" },
                  ]}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <FooterItem
                    link={{ linkTitle: "Contact", linkUrl: "/contact" }}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <FooterItem
                    link={{ linkTitle: "Work For Us", linkUrl: "/apply" }}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <FooterStack
                  title="Employee Links"
                  links={[
                    {
                      linkTitle: "Payroll",
                      linkUrl: "https://www.adp.com/logins.aspx",
                      external: true,
                    },
                    {
                      linkTitle: "Safety Training",
                      linkUrl: "https://www.safetysoft.com",
                      external: true,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: `2px 0px`,
          background: "black",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2" color="#ffffff8c">
          August Groh & Sons, Inc | Developed by JT Groh - 2022
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
